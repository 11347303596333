import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
// import { map } from "lodash"
// import is from "is_js"
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */
// let backgroundPosition = is.mobile() ? Math.floor(Math.random() * 100) : 55
let backgroundPosition = 55
const Image = ({ src }) => {
  const data = useStaticQuery(graphql`
    query {
      goSeeImage: file(relativePath: { eq: "gosee.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mapImage: file(relativePath: { eq: "loving-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      profileVJ: file(relativePath: { eq: "profile_vj.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      voyaygeWorld: file(relativePath: { eq: "voyayge-world.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      traveler: file(relativePath: { eq: "traveler.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      designer: file(relativePath: { eq: "designer.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      coo: file(relativePath: { eq: "coo.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      kiran: file(relativePath: { eq: "kiran.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tammy: file(relativePath: { eq: "tammy.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rosalie: file(relativePath: { eq: "rosalie.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      drew: file(relativePath: { eq: "drew.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lynn: file(relativePath: { eq: "lynn.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      worldMap: file(relativePath: { eq: "world-map-1920.png" }) {
        id
        relativePath
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      planyourtrip: file(relativePath: { eq: "planyourtrip.jpg" }) {
        id
        relativePath
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      traveldesigner: file(relativePath: { eq: "traveldesigner.png" }) {
        id
        relativePath
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  switch (src) {
    case "gosee.png":
      return <Img fluid={data.goSeeImage.childImageSharp.fluid} />
      break
    case "profile_vj.png":
      return <Img fluid={data.profileVJ.childImageSharp.fluid} />
      break
    case "voyayge-world.png":
      return <Img fluid={data.voyaygeWorld.childImageSharp.fluid} />
      break
    case "map.png":
      return <Img fluid={data.mapImage.childImageSharp.fluid} />
      break
    case "traveler.png":
      return <Img fluid={data.traveler.childImageSharp.fluid} />
      break
    case "designer.png":
      return <Img fluid={data.designer.childImageSharp.fluid} />
      break
    case "coo.png":
      return <Img fluid={data.coo.childImageSharp.fluid} />
      break
    case "kiran":
      return <Img fluid={data.kiran.childImageSharp.fluid} />
      break
    case "drew":
      return <Img fluid={data.drew.childImageSharp.fluid} />
      break
    case "rosalie":
      return <Img fluid={data.rosalie.childImageSharp.fluid} />
      break
    case "tammy":
      return <Img fluid={data.tammy.childImageSharp.fluid} />
      break
    case "lynn":
      return <Img fluid={data.lynn.childImageSharp.fluid} />
      break
    case "traveldesigner":
      return <Img fluid={data.traveldesigner.childImageSharp.fluid} />
      break
    case "worldMap":
      return (
        <Img
          fluid={data.worldMap.childImageSharp.fluid}
          style={{ position: "unset" }}
          imgStyle={{
            // animation: "imagemove 100s infinite",
            // webkitAnimation: "imagemove 100s infinite",
            // animationDirection: "alternate",
            // animationTimingFunction: "linear",
            objectPosition: `${backgroundPosition}% center`,
          }}
        />
      )
      break
    case "planyourtrip":
      return (
        <Img
          fluid={data.planyourtrip.childImageSharp.fluid}
          style={{ position: "unset" }}
          imgStyle={{
            // animation: "imagemove 100s infinite",
            // webkitAnimation: "imagemove 100s infinite",
            // animationDirection: "alternate",
            // animationTimingFunction: "linear",
            objectPosition: `${backgroundPosition}% center`,
          }}
        />
      )
      break

    default:
      break
  }
}

export default Image
